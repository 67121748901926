import styled from "styled-components"
import devices from "../../styles/devices"

import BannerSobre from "../../assets/banner-home.jpg"

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: url(${BannerSobre});
  background-size: cover;
  color: white;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 2px 0px rgba(0, 0, 0, 0.35);

  @media (max-width: ${devices.mobileL}) {
    text-align: center;
  }
`

export const HeroTitle = styled.h1`
  font-size: 42px;

  @media (max-width: ${devices.mobileL}) {
    font-size: 32px;
  }
`

export const HeroSubtitle = styled.p``

export const Divider = styled.div`
  height: 4px;
  width: 62px;
  background: #fff;
  margin: 16px 0;
`

export const Descriptions = styled.div`
  display: flex;

  @media (max-width: ${devices.mobileL}) {
    > * {
      + * {
        margin-top: 40px;
      }
    }

    flex-direction: column;
    padding: 0 40px;
  }
`

export const Images = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0;

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;
  }
`

export const Image = styled.img`
  height: 320px;

  @media (max-width: ${devices.mobileL}) {
    + img {
      margin-top: 20px;
    }
  }
`
export const Quality = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    padding: 32px 0;
  }
`

export const QualityItem = styled.div`
  @media (max-width: ${devices.mobileL}) {
    margin-top: 40px;
  }
`

export const QualityItems = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;
    padding: 0 40px;
  }
`
