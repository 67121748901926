import React from "react"

import DefaultLayout from "../../layouts/default"

import Content from "../../components/Content"
import Description from "../../components/Description"

import Sobre1 from "../../assets/imagem-sobre-01.jpg"
import Home1 from "../../assets/imagem-01-home.jpg"
import Seta from "../../assets/seta.jpg"

import {
  Hero,
  Divider,
  HeroTitle,
  HeroSubtitle,
  Descriptions,
  Images,
  Image,
  Quality,
  QualityItems,
  QualityItem,
} from "./styles.styled"

export default () => (
  <DefaultLayout>
    <Hero>
      <HeroTitle>Sobre a rentalsef</HeroTitle>
      <Divider />
      <HeroSubtitle>Conheça mais sobre a gente</HeroSubtitle>
    </Hero>
    <Content>
      <Descriptions>
        <Description title="História e missão">
          Desde 2009, buscamos promover eficiência e apresentar soluções
          inteligentes em locação de equipamentos diferenciados para
          modernização e otimização de serviços ao cliente, potencializando
          resultados operacionais com efeiciências e compromentimento
          socioambiental.
        </Description>
        <Description title="Visão e valores">
          Ser reconhecida como uma empresa inovadora e incentivadora do
          aprimoramento das relações sociais, ambientais e tecnológicas.
          Respeitando sempre à vida, em compromisso com a ética e a verdade.
        </Description>
      </Descriptions>
      <Images>
        <Image src={Sobre1} />
        <Image src={Home1} />
      </Images>
      <Quality>
        <Description centered title="Qualidade" />
        <img src={Seta} alt="" />
        <QualityItems>
          <QualityItem>
            <Description centered title="Durabilidade">
              Oferecendo sempre os melhores equipamentos para locação
            </Description>
          </QualityItem>
          <QualityItem>
            <Description centered title="Modernidade">
              Máquinas de ponta, sempre atualizadas e renovadas
            </Description>
          </QualityItem>
          <QualityItem>
            <Description centered title="Sustentabilidade">
              Cuidado redobrado com o descarte e impactos com o meio ambiente
            </Description>
          </QualityItem>
        </QualityItems>
      </Quality>
    </Content>
  </DefaultLayout>
)
